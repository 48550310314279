// src/analytics.js
/* global dataLayer */
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
  
    gtag('config', 'G-GNKEVT7HKH');
  };
  
  export const loadScript = (url) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    document.head.appendChild(script);
  };
  