import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './services.css';

const Services = () => {
  const services = [
    {
      title: "Home Cleaning (Standard)",
      desc: "Standard home cleaning services to keep your home spotless and fresh.",
      details: [
        "Vacuum all floors",
        "Dust all surfaces and furniture (picture frames and decor)",
        "Clean all mirrors",
        "Empty all trash/recycling",
        "Change linens/make beds",
        "Living Room: Dust/wipe all surfaces, vacuum upholstery and carpets, clean electronic services, clean window sills",
        "Kitchen: Clean counters, wipe down appliances, clean sink, clean inside and outside of microwave, clean stovetop, vacuum and mop floors, empty and clean garbage disposal",
        "Bathroom: Scrub and disinfect sink, toilet, and bathtub, clean mirrors, vacuum and mop floors, empty trash, replenish toilet paper",
        "Bedrooms: Dust and wipe down surfaces, vacuum carpets, clean mirrors, clean window sills"
      ],
      image: "/residential_cleaning.jpg",
      id: "home-cleaning"
    },
    {
      title: "Deep Cleaning",
      desc: "Comprehensive deep cleaning services for a thorough clean.",
      details: [
        "All standard cleaning services",
        "Clean inside and outside of oven",
        "Clean top, front, and inside of stove",
        "Clean inside and outside of refrigerator",
        "Handwipe all baseboards, trims, and doors",
        "Clean all ceiling fans",
        "Clean inside of washer/dryer"
      ],
      image: "/deep_cleaning.jpg",
      id: "deep-cleaning"
    },
    {
      title: "Commercial Cleaning",
      desc: "Professional cleaning services for office and commercial spaces.",
      details: [
        "Vacuum and mop all floors",
        "Wipe down all mirrors and windows",
        "Sanitize toilets, counters, and more",
        "Empty all trash cans",
        "Clean all desks",
        "Vacuum and mop bathrooms",
        "Wipe down all surfaces in bathrooms"
      ],
      image: "/office_cleanup.jpg",
      id: "commercial-cleaning"
    },
    {
      title: "Post-Construction Clean-up",
      desc: "Detailed clean-up services after construction work.",
      details: [
        "Remove debris and dust",
        "Clean all surfaces and floors",
        "Ensure the new space is pristine and ready for use"
      ],
      image: "/construction_cleanup.jpg",
      id: "post-construction-cleanup"
    },
    {
      title: "Move In/Move Out Cleaning",
      desc: "Specialized cleaning services for moving in or out of a space.",
      details: [
        "Deep cleaning and sanitization",
        "Ensure a smooth transition"
      ],
      image: "/move_in_out.jpg",
      id: "move-in-out-cleaning"
    },
    {
      title: "Party Help",
      desc: "Assistance with setup and cleanup for parties and events.",
      details: [
        "Help with decorations and setup",
        "Assist with food service",
        "Post-event cleaning"
      ],
      image: "/party_setup.jpg",
      id: "party-help"
    }
  ];

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Scroll to the element when the component mounts
    handleHashChange();

    // Add the event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div className="services-page">
      <div className="container">
        <h2>Our Services</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <Link key={index} to={`/contact?service=${service.title}`} className="service-card">
              <div className="service-card-content" id={service.id}>
                <img src={service.image} alt={service.title} className="service-image"/>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.desc}</p>
                  <ul>
                    {service.details.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
