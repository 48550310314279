import React, { useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './homepage.css';
import { BrowserRouter as Router, Routes, Route, NavLink, useNavigate } from 'react-router-dom';
import Contact from './contact';
import Services from './services';
import AboutUs from './aboutus';
import useScrollToTop from './useScrollToTop'; 
import { initGA, loadScript } from './analytics';

const reviews = [
  {
    text: "Pesarini Cleaning Service is absolutely amazing!!! I have had the pleasure to have Fernanda's professional team of four ladies, once a week, to clean my home; for the past year. The ladies are extremely accommodating, polite and treat my home as if it's their own. My daughter recommended this cleaning service to me; as she has had them for a few years. I adore their attention to detail and my home is so fresh and shiny when they're done. I highly suggest and recommend their services ~ to my friends, family and if you're reading this post ... you should too. A sincere Thank You to Fernanda and her staff:)",
    author: "Suzanne B",
    link: "https://www.facebook.com/PesariniCleaningService/reviews"
  },
  {
    text: "Fernanda and her team are the best of the best. She is so accommodating on scheduling and times. She does our house once a month but also has done deep cleaning after construction and you would never know it was ever done. She is very kind and goes out of her way to make sure the customer is happy. I highly recommend her; she will make your home sparkle. Her team is the same way; anything you want they do.",
    author: "Mara C",
    link: "https://www.facebook.com/PesariniCleaningService/reviews"
  },
  {
    text: "Fernanda and her team do an incredible job. They are so prepared, punctual, efficient and thorough. We couldn’t be more pleased!",
    author: "Michelle M",
    link: "https://www.facebook.com/PesariniCleaningService/reviews"
  },
  {
    text: "Fernanda, Paola and their team are excellent! We have been using them for years now and they are always communicative, thorough and speedy! Highly recommend this group of ladies. Thank you Pesarini!",
    author: "Amanda W",
    link: "https://www.facebook.com/PesariniCleaningService/reviews"
  }
];


const Home = () => {
  const handleScrollToContact = () => {
    const contactSection = document.getElementById('contact');
    const offsetTop = contactSection.offsetTop - 100; // Adjust the offset as needed
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <section id="hero" className="hero">
        <div className="hero-content">
          <h2>Relax, We'll take care of everything!</h2>
          <p>Excellent local references • Since 2005</p>
          <button className="cta-button" onClick={handleScrollToContact}>Get a Free Estimate</button>
        </div>
      </section>
      <About />
      <ServiceOverview />
      <Reviews />
      <Contact />
    </>
  );
};


const About = () => (
  <section id="about" className="about">
    <div className="container">
      <h3>About Us</h3>
      <p>Welcome to Pesarini Cleaning Service, a family-owned business proudly serving Ridgefield, Redding, Wilton, North Salem, South Salem, Lewisboro, and Waccabuc for over 15 years. 
        Our expert team specializes in home cleaning, commercial cleaning, and post-construction clean-up. We are dedicated to providing exceptional cleaning services 
        tailored to your needs. Choose Pesarini Cleaning Service for a spotless and fresh environment in your home or business.</p>
    </div>
  </section>
);


const ServiceOverview = () => {
  const navigate = useNavigate();

  const services = [
    { src: "/residential_cleaning.jpg", alt: "Home Cleaning", title: "Home Cleaning (Standard)", desc: "Standard home cleaning services to keep your home spotless and fresh.", id: "home-cleaning" },
    { src: "/deep_cleaning.jpg", alt: "Deep Cleaning", title: "Deep Cleaning", desc: "Comprehensive deep cleaning services for a thorough clean.", id: "deep-cleaning" },
    { src: "/office_cleanup.jpg", alt: "Commercial Cleaning", title: "Commercial Cleaning", desc: "Professional cleaning services for office and commercial spaces.", id: "commercial-cleaning" },
    { src: "/construction_cleanup.jpg", alt: "Post-Construction Clean-up", title: "Post-Construction Clean-up", desc: "Detailed clean-up services after construction work.", id: "post-construction-cleanup" },
    { src: "/move_in_out.jpg", alt: "Move In/Move Out Cleaning", title: "Move In/Move Out Cleaning", desc: "Specialized cleaning services for moving in or out of a space.", id: "move-in-out-cleaning" },
    { src: "/party_setup.jpg", alt: "Party Help", title: "Party Help", desc: "Assistance with setup and cleanup for parties and events.", id: "party-help" }
  ];

  const handleServiceClick = (id) => {
    navigate(`/services#${id}`);
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -100; // Adjust this value as needed
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100); // Delay to ensure navigation has completed
  };

  return (
    <section id="services" className="services">
      <div className="container">
        <h3>Our Services</h3>
        <div className="services-row">
          {services.map((service, index) => (
            <div key={index} className="service-card" onClick={() => handleServiceClick(service.id)}>
              <img src={service.src} alt={service.alt} className="service-image"/>
              <div className="service-info">
                <h4>{service.title}</h4>
                <p>{service.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Reviews = () => (
  <section id="reviews" className="reviews">
    <div className="container">
      <h3>What Our Clients Say</h3>
      <div className="reviews-container">
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <p>"{review.text}"</p>
            <p><small>- {review.author}</small></p>
            <a href={review.link} target="_blank" rel="noopener noreferrer" className="review-link">
              Read more
            </a>
            <div className="review-stars">
              {[...Array(5)].map((_, i) => <FaStar key={i} className="star-icon"/> )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const MainContent = () => {
  useScrollToTop(); // Use the custom hook to scroll to top on route change
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

const HomePage = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="Homepage">
      <header className="header">
        <div className="header-content">
          <div onClick={handleLogoClick} className="logo-container" style={{ cursor: 'pointer' }}>
            <img src="logo.png" alt="Pesarini Cleaning Services Logo" className="logo-image"/>
            <span className="logo-text">Pesarini Cleaning Services</span>
          </div>
          <nav className="navigation">
            <NavLink to="/" className="nav-link" end>Home</NavLink>
            <NavLink to="/about" className="nav-link">About Us</NavLink>
            <NavLink to="/services" className="nav-link">Services</NavLink>
            <NavLink to="/contact" className="nav-link">Contact</NavLink>
          </nav>
        </div>
      </header>
      <MainContent />
      <footer className="footer">
        <div className="container">
          <p>Contact us directly: (203) 942-9906 | <a href="mailto:pesarinicleaning@gmail.com">pesarinicleaning@gmail.com</a></p>
          <p>Follow us on social media:</p>
          <p>
            <a href="https://www.facebook.com/PesariniCleaningService" target="_blank" rel="noopener noreferrer">Facebook</a> | 
            <a href="https://www.instagram.com/pesarini_cleaning_services/" target="_blank" rel="noopener noreferrer"> Instagram</a>
          </p>
        </div>
      </footer>
    </div>
  );
};


const App = () => {
  useEffect(() => {
    loadScript('https://www.googletagmanager.com/gtag/js?id=G-GNKEVT7HKH');
    initGA();
  }, []);

  return (
    <Router>
      <HomePage />
    </Router>
  );
};

export default App;