import React, { useState, useEffect } from 'react';
import './contact.css';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Contact = () => {
  const query = useQuery();
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', town: '', serviceType: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const serviceType = query.get('service');
    if (serviceType) {
      setFormData((prevFormData) => ({ ...prevFormData, serviceType }));
    }
  }, [query]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    let errorsCopy = { ...errors };
    if (id === 'email' && !validateEmail(value)) {
      errorsCopy.email = 'Invalid email address';
    } else if (id === 'phone' && !validatePhone(value)) {
      errorsCopy.phone = 'Phone number must be 10 digits';
    } else {
      delete errorsCopy[id];
    }
    setErrors(errorsCopy);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError('');

    const formErrors = {};
    if (!validateEmail(formData.email)) {
      formErrors.email = 'Invalid email address';
    }
    if (!validatePhone(formData.phone)) {
      formErrors.phone = 'Phone number must be 10 digits';
    }
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      setLoading(false);
      return;
    }

    const form = e.target;

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(new FormData(form)).toString(),
      });

      if (response.ok) {
        setSuccess(true);
        form.reset();
        setFormData({ name: '', email: '', phone: '', town: '', serviceType: '', message: '' });
      } else {
        setError("Failed to submit form");
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <h3>Get a Free Estimate</h3>
        <form
          className="contact-form"
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Your Name"
              onChange={handleChange}
              value={formData.name}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Your Email"
              onChange={handleChange}
              value={formData.email}
              required
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="form-control"
              placeholder="Your Phone Number"
              onChange={handleChange}
              value={formData.phone}
              required
            />
            {errors.phone && <p className="error-message">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="town">Town</label>
            <input
              type="text"
              id="town"
              name="town"
              className="form-control"
              placeholder="Your Town"
              onChange={handleChange}
              value={formData.town}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="serviceType">Which type of service?</label>
            <select
              id="serviceType"
              name="serviceType"
              className="form-control"
              onChange={handleChange}
              value={formData.serviceType}
              required
            >
              <option value="" disabled>Select a service</option>
              <option value="Home Cleaning (Standard)">Home Cleaning (Standard)</option>
              <option value="Deep Cleaning">Deep Cleaning</option>
              <option value="Commercial Cleaning">Commercial Cleaning</option>
              <option value="Post-Construction Clean-up">Post-Construction Clean-up</option>
              <option value="Move In/Move Out Cleaning">Move In/Move Out Cleaning</option>
              <option value="Party Help">Party Help</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              className="form-control"
              rows="5"
              placeholder="How can we help you?"
              onChange={handleChange}
              value={formData.message}
              required
            ></textarea>
          </div>
          <button type="submit" className="cta-button" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {success && <p className="success-message">Your request has been submitted successfully!</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </section>
  );
};

export default Contact;
