import React from 'react';
import './aboutus.css'; // Import specific CSS for the About Us section

const AboutUsFull = () => {
  return (
    <section id="about-us" className="about-us">
      <div className="container">
        <h2>About Us</h2>
        <p>Welcome to Pesarini Cleaning Service, where cleaning is not just a task, but a tradition upheld for over 15 years. As a family-owned business operating in Ridgefield, CT, with extensions to select towns in Fairfield County, we take pride in our work. Our dedicated team of trustworthy individuals are experts in organization, sanitization, and cleaning.</p>
        <p>From arranging your spaces to thoroughly eradicating germs, we go above and beyond to ensure every corner of your home or business is fresh and clean. With over 15 years of experience and dozens of clients in the community, we understand the unique needs of our clients and strive to exceed expectations with every service.</p>
        <p>At Pesarini Cleaning Services, we believe in more than just leaving a space spotless; we believe in fostering environments where families thrive, businesses flourish, and memories are made. Choose Pesarini Cleaning Service for all of your cleaning needs.</p>
      </div>
    </section>
  );
};

export default AboutUsFull;
